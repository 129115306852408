// Imports
import { h } from 'preact';

function ProfileImage({ src, alt }) {
    return src ? <img src={src} alt={alt} /> : null;
}

function renderBioHeadline(bioHeadline) {
    return { __html: bioHeadline };
}

export function UserSummary({ hit, trackingEvent = '' }) {
    const profileImage = hit.profileImage?.[100] ?? null;

    return (
        <div className="border-b border-gray-200 mb-8 pb-8">
            <div className="flex gap-4 items-center text-slight md:gap-6">
                <div>
                    <div className="bg-gray-200 border overflow-hidden rounded-full shadow-profile shrink-0 inline-block h-12 w-12">
                        <a href={hit.url} title={`All stories by ${hit.fullname}`} data-track-click={`u;${hit.id};${trackingEvent}`}>
                            <ProfileImage src={profileImage} alt={hit.fullname} />
                        </a>
                    </div>
                </div>
                <div>
                    <h3 className="type-h6 font-sans font-bold mb-0.5">
                        <a href={hit.url} title={`All stories by ${hit.fullname}`} data-track-click={`u;${hit.id};${trackingEvent}`}>{hit.fullname}</a>
                    </h3>
                    <div className="line-clamp-2 text-gray-600" dangerouslySetInnerHTML={renderBioHeadline(hit.bioHeadline)} />
                </div>
            </div>
        </div>
    );
}

export function UserOverview({ hit, trackingEvent = '' }) {
    const profileImage = hit.profileImage?.[100] ?? null;

    return (
        <div className="flex gap-2 mb-2.5 text-slight">
            <div>
                <div className="bg-gray-200 border overflow-hidden rounded-full shadow-profile shrink-0 inline-block h-8 w-8">
                    <a href={hit.url} title={`All stories by ${hit.fullname}`} data-track-click={`u;${hit.id};${trackingEvent}`}>
                        <ProfileImage src={profileImage} alt={hit.fullname} />
                    </a>
                </div>
            </div>
            <div>
                <h3 className="type-h6 font-sans font-bold mb-0.5">
                    <a href={hit.url} title={`All stories by ${hit.fullname}`} data-track-click={`u;${hit.id};${trackingEvent}`}>{hit.fullname}</a>
                </h3>
                <div className="line-clamp-2 text-gray-600" dangerouslySetInnerHTML={renderBioHeadline(hit.bioHeadline)} />
            </div>
        </div>
    );
}
